<template>
  <b-card
    :header="$i18n.t('navigation.role-management')"
    header-bg-variant="primary"
    header-text-variant="white"
  >
    <b-modal
      id="assign-role-modal"
      no-footer
      ok-only
      ok-variant="danger"
      size="md"
      :ok-title="$t('global.cancel')"
      centered
      :title="selectedUserName"
    >
      <b-form-group :label="chooseLabelText">
        <b-form-checkbox-group
          v-if="userRoles"
          v-model="selected"
          :options="userRoles"
          disabled-field="notEnabled"
          stacked
        />
      </b-form-group>
      <update-button
        v-if="userRoles"
        :isBusy="isBusy"
        @updateFunction="validationForm"
      />
    </b-modal>
    <b-table
      sticky-header
      :items="admins"
      :fields="fields"
      bordered
      hover
      class="shadow-sm rounded products-table"
      thead-tr-class="order-main-thead"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      small
      @row-clicked="(e) => onRowClick(e)"
    />
  </b-card>
</template>

<script>
import { required, email } from "@validations";
import {
  BFormGroup,
  BTable,
  BModal,
  BCard,
  BFormCheckboxGroup,
} from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import roles from "./roles.json";
import UpdateButton from "../components/UpdateButton/UpdateButton.vue";

export default {
  components: {
    BFormGroup,
    BTable,
    BFormCheckboxGroup,
    BModal,
    BCard,
    UpdateButton,
  },
  data() {
    return {
      required,
      email,
      selectedUserId: null,
      selected: [],
      options: roles,
      selectedUserName: null,
    };
  },
  computed: {
    ...mapState("roleManagement", ["userRoles", "admins", "isBusy"]),
    chooseLabelText() {
      if (this.userRoles) {
        return this.$t("users.choseRoles");
      }
      return "";
    },
    fields() {
      return [
        {
          key: "displayId",
          label: this.$t("users.id"),
          sortable: false,
        },
        {
          key: "firstName",
          label: this.$t("users.firstName"),
          sortable: false,
        },
        {
          key: "lastName",
          label: this.$t("users.lastName"),
          sortable: false,
        },
        {
          key: "email",
          label: this.$t("users.email"),
          sortable: false,
        },
      ];
    },
  },
  watch: {
    userRoles(val) {
      // console.log(val);
      this.selected = val
        .filter((res) => res.isEnabled)
        .map((ele) => ele.value);
    },
  },
  created() {
    this.getAdmins();
  },
  beforeDestroy() {
    this.$store.commit("roleManagement/CLEAR_USER_ROLES");
  },
  methods: {
    ...mapActions("roleManagement", [
      "setUserRole",
      "getUserRole",
      "getAdmins",
    ]),
    toast(message, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: message,
          icon: "BellIcon",
          variant,
        },
      });
    },
    async onRowClick(e) {
      this.selectedUserId = e.id;
      try {
        await this.getUserRole({
          email: e.email,
          id: e.id,
        });
        this.selectedUserName = `${e.firstName} ${e.lastName}`;
        this.$bvModal.show("assign-role-modal");
      } catch (error) {
        console.log(error);
      }
    },
    async validationForm() {
      const payload = {
        id: this.selectedUserId,
        roles: this.userRoles.map((x) => ({
          id: x.roleId,
          isEnabled: this.selected.includes(x.value),
        })),
      };
      try {
        await this.setUserRole(payload);
        this.toast("Roles updated successfully", "success");
        this.$bvModal.hide("assign-role-modal");
        this.getAdmins();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss">
.modal-body {
  display: block;
}
.checkboxWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.inputAndButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4rem;
  @media (max-width: 600px) {
    flex-direction: column;
    height: unset;
  }
}

.getRolesButton {
  width: 20%;
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 15px;
  }
}

.inputAndButtonWrapper input {
  width: 70%;
  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
  height: 100%;
}
</style>
